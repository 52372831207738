import Vue from 'vue'

export const userKey = '__Gestor__De__Projetos__Nutrimix__*WasLar*AVIZVFX*'
/* export const rutPregao = 'http://localhost:1020'
export const urlApi = 'http://localhost:9191' */
export const rutPregao = 'https://api.nutrimix.app'
export const urlApi = 'https://gestorv2-production.up.railway.app'

export function showError(e, msgPersonalizada) {
    if (e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({
            msg: e.response.data
        })
    } else if (typeof e === 'string') {
        Vue.toasted.global.defaultError({
            msg: e
        } + msgPersonalizada)
    } else {
        Vue.toasted.global.defaultError()
    }
}

export default {
    urlApi,
    showError
}